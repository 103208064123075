export function envSettingTrue(setting) {
  return process.env[`REACT_APP_${setting}`]?.toLowerCase() === 'true';
}

export function getEnvSetting(setting) {
  return process.env[`REACT_APP_${setting}`];
}

export const QA_HOSTS = [
  'mypsycaptest.com',
  'mypsycap-preview.com',
  'talent-passport.vercel.app',
  'truepathqa.ai',
  'localhost:5005',
];

export const PROD_HOSTS = ['truepath.ai'];
export const STAGING_HOSTS = ['truepathstaging.ai'];

export const ENV_PROD = PROD_HOSTS.some(
  (host) =>
    host === window.location.hostname || window.location.host.endsWith(host),
);

export const ENV_STAGING = STAGING_HOSTS.some(
  (host) =>
    host === window.location.hostname || window.location.host.endsWith(host),
);

export const ENV_QA = QA_HOSTS.some(
  (host) =>
    host === window.location.hostname || window.location.host.endsWith(host),
);

export const ENV_LOCAL = process.env.NODE_ENV === 'development';
export const ENV_PREVIEW = window?.location?.host.includes('mypsycaptest');

export const SIGN_UP_FIRST = envSettingTrue('SHOW_SIGNUP_FIRST');

export const ENV_TEST = window.Cypress;

export const getEnvironment = () => {
  if (ENV_PROD) {
    return 'production';
  }
  return ENV_QA ? 'qa' : 'local';
};

export const getDomain = () => {
  if (ENV_PROD) {
    return '.truepath.ai';
  }

  if (ENV_QA) {
    return '.truepathqa.ai';
  }

  if (ENV_STAGING) {
    return '.truepathstaging.ai';
  }

  if (ENV_PREVIEW) {
    return `.mypsycaptest.com`;
  }

  if (ENV_LOCAL) {
    return 'localhost:5005';
  }

  return '.truepath.ai';
};
