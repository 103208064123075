import { lazy, Suspense } from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes } from 'react-router-dom';

import { FeatureFlags } from '../constants/featureFlags';
import useFeatureFlag from '../hooks/useFeatureFlag';
import useV4Features from '../hooks/useV4Features';
import ErrorBoundary from './ErrorBoundary';
import KoiLandingPage from './LandingPage/KoiLandingPage';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const EmailVerification = lazy(() => import('./Auth/EmailVerification'));
const LoginPage = lazy(() => import('./Auth/LoginPage'));
const Logout = lazy(() => import('./Auth/Logout'));
const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
const SignUpPage = lazy(() => import('./Auth/SignUpPage'));
const DefaultTemplate = lazy(() => import('./Builder/DefaultTemplate'));
const ResumeDownload = lazy(() => import('./Builder/ResumeDownload'));
const Checkout = lazy(() => import('./Checkout/Checkout'));
const PaymentPlans = lazy(() => import('./Checkout/PaymentPlans'));
const InvitePage = lazy(() => import('./Invite/InvitePage'));
const DoInvite = lazy(() => import('./Invite/DoInvite'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const Design = lazy(() => import('./Design/Design'));
const DocumentBuilder = lazy(() => import('./DocumentBuilder/DocumentBuilder'));
const DocumentBuilderPage = lazy(
  () => import('./DocumentBuilderPage/DocumentBuilderPage'),
);
const EditCoverLetterPage = lazy(
  () => import('./EditCoverLetterPage/EditCoverLetterPage'),
);
const EditCoverLetterTemplatePage = lazy(
  () => import('./EditCoverLetterTemplatePage/EditCoverLetterTemplatePage'),
);
const EditResumePage = lazy(() => import('./EditResumePage/EditResumePage'));
const EditResumeTemplatePage = lazy(
  () => import('./EditResumeTemplatePage/EditResumeTemplatePage'),
);
const EditResumeSkillsPage = lazy(
  () => import('./EditResumeSkillsPage/EditResumeSkillsPage'),
);
const StaticPlaceholder = lazy(() => import('./LandingPage/StaticPlaceholder'));
const AssessmentResults = lazy(() => import('./Results/AssessmentResults'));

const ResumeBuilderPage = lazy(
  () => import('./ResumeBuilderPage/ResumeBuilderPage'),
);
const SubscriptionCanceledPage = lazy(
  () => import('./SubscriptionCanceledPage/SubscriptionCanceledPage'),
);
const TakeTest = lazy(() => import('./TakeTest/TakeTest'));
const AccountPage = lazy(() => import('./Account/AccountPage'));
const AdminPortal = lazy(() => import('./AdminPortal/AdminPortal'));

export default function AppRoutes() {
  const showReferralUI = useFeatureFlag(FeatureFlags.V_1_3_REFERRAL_UI, false);
  const V_4_FEATURES_ENABLED = useV4Features();

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Routes>
          <Route element={<PublicRoutes onlyGuests />}>
            <Route path="/login" element={<LoginPage />} exact />
            <Route path="/password-reset" element={<ResetPassword />} exact />
            <Route path="/static" element={<StaticPlaceholder />} exact />
          </Route>

          <Route element={<PublicRoutes />}>
            <Route path="/assessment" element={<TakeTest />} exact />
            <Route path="/sign-up" element={<SignUpPage />} exact />
            <Route path="/design" element={<Design />} exact />
            <Route
              path="/invite/:referrer_invite_link"
              element={<DoInvite />}
              exact
            />
            <Route
              path="/profile/:hash"
              element={<AssessmentResults publicPage />}
              exact
            />
            <Route
              path="/resume-builder"
              element={<ResumeBuilderPage />}
              exact
            />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="/checkout" element={<Checkout />} exact />
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/plans" element={<PaymentPlans />} exact />
            {showReferralUI && (
              <Route path="/invite" element={<InvitePage />} exact />
            )}
            <Route path="/results" element={<AssessmentResults />} exact />
            <Route path="/verify" element={<EmailVerification />} exact />
            <Route
              path="/builder/cover-template"
              element={<DefaultTemplate type="cover_letter" />}
              exact
            />
            <Route
              path="/builder/resume-template"
              element={<DefaultTemplate type="resume" />}
              exact
            />
            <Route path="/view-resume/:id" element={<ResumeDownload />} exact />
            <Route path="/edit-resume/:id" element={<EditResumePage />} exact />
            <Route
              path="/edit-resume/:id/template"
              element={<EditResumeTemplatePage />}
              exact
            />
            <Route
              path="edit-resume/:id/skills"
              element={<EditResumeSkillsPage />}
              exact
            />
            <Route
              path="/edit-cover-letter/:id"
              element={<EditCoverLetterPage />}
              exact
            />
            <Route
              path="/edit-cover-letter/:id/template"
              element={<EditCoverLetterTemplatePage />}
              exact
            />
            <Route
              path="/document-builder"
              element={
                V_4_FEATURES_ENABLED ? (
                  <DocumentBuilderPage />
                ) : (
                  <DocumentBuilder />
                )
              }
              exact
            />

            <Route path="/settings" element={<AccountPage />} exact />
            <Route
              path="/subscription-canceled"
              element={<SubscriptionCanceledPage />}
              exact
            />
          </Route>

          <Route element={<PrivateRoutes superUser />}>
            <Route path="/admin" element={<AdminPortal />} exact />
          </Route>

          <Route path="/logout" element={<Logout />} exact />
          <Route exact path="oauth/linkedin" element={<LinkedInCallback />} />
          <Route exact path="oauth/office365" element={<LinkedInCallback />} />

          <Route path="*" element={<KoiLandingPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
