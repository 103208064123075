import { useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { FeatureFlags } from './constants/featureFlags';
import useFeatureFlag from './hooks/useFeatureFlag';
import useStreaming from './hooks/useStreaming';
import useTracking from './hooks/useTracking';
import AppRoutes from './routes/AppRoutes';
import AppContext from './store/AppContext';
import { api } from './utils/api';
import Tracking from './utils/tracking';
import { setUserForProviders } from './utils/user';

import './modals/Modal.scss';

Tracking.initialize();

export default function App() {
  const [, dispatch] = useContext(AppContext);
  const ldClient = useLDClient();
  const fakeDoorEnabled = useFeatureFlag(FeatureFlags.FAKE_DOOR, false);

  useTracking();
  useStreaming();

  // Fetch logged in user if access token cookie is present
  useEffect(() => {
    const trackReferralCompletion = async (user) => {
      if (user?.referrer_count > 2 && !user?.referrals_completion) {
        const { data } = await api.put(`/users/${user.id}`, {
          user: { referrals_completion: true },
        });
        dispatch({ type: 'currentUser/set', payload: data });

        Tracking.trackConditionMet({
          widget_name: 'Unlock Free Month',
          context: 'Referrals',
        });
      }
    };

    const getLoggedInUser = async () => {
      try {
        dispatch({ type: 'loadingLoggedInUser/set', payload: true });

        const { data } = await api.get('/users/me');
        dispatch({ type: 'currentUser/set', payload: data });
        await trackReferralCompletion(data);
        await setUserForProviders(data, ldClient, false, 'App');
        dispatch({ type: 'loadingLoggedInUser/set', payload: false });
        logExperimentStartedEvents(data);
      } catch {
        dispatch({ type: 'loggedIn/set', payload: false });
        dispatch({ type: 'loadingLoggedInUser/set', payload: false });
      }
    };

    if (!Cookies.get('talent__api_access_token')) {
      dispatch({ type: 'loggedIn/set', payload: false });
      logExperimentStartedEvents();
    } else {
      dispatch({ type: 'loggedIn/set', payload: true });
      getLoggedInUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ldClient]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const logExperimentStartedEvents = async (currentUser) => {
    const STORED_LD_CONTEXT_KEY = Cookies.get('LD_CONTEXT_KEY');
    const newDevice = currentUser?.mtr !== STORED_LD_CONTEXT_KEY;
    const userProperties = {
      user_id: currentUser?.id || 'No user',
      user_mtr: currentUser?.mtr || 'No user',
      ld_context_key_cookie: STORED_LD_CONTEXT_KEY,
      from_view: window.location.pathname,
      new_device: newDevice,
    };

    //Just for safet with no impact in user experience, Ensure flags are correctly fetched
    await sleep(2000);
    Tracking.trackLDExperiment({
      'Experiment name': FeatureFlags.FAKE_DOOR,
      'Variant name': fakeDoorEnabled,
      userProperties,
    });
  };

  return (
    <>
      <AppRoutes />
    </>
  );
}
